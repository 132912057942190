import React, {useEffect, useState} from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ReferenceLine, ResponsiveContainer
} from "recharts";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import axios from "axios";
import {CircularProgress, Typography} from "@material-ui/core";

export default function JourneyTimelineChart({cycleUuid}) {

    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/kegs/${cycleUuid}/temperature-timeline`);
    const [timelineUrl, setTimelineUrl] = useState(baseUrl);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(timelineUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data;
                const newData = result.data.map((record, index) => ({
                    id: index,
                    Date: record.date,
                    DateTime: record.kegEventTime,
                    Temperature: parseFloat(record.kegTemperature),
                    // Event: record.events,
                    // Angle: record.kegVerticalAngle
                }));
                setData(newData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [timelineUrl]);

    const formatYAxis = (tickItem) => {
        // console.log(window.navigator.userLanguage)

        return tickItem + ' °C';
    }

    const formatXAxis = (tickItem) => {
        // console.log(window.navigator.userLanguage)

        return new Date(tickItem).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB', { day: 'numeric', month: 'short'});
        // return new Date(tickItem).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB', { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true });
    }

    const CustomizedDot = props => {
        const {cx, cy, stroke, payload, value} = props;

        if (payload.id%(data.length/10) < 1 || payload.id == data.length-1) {
            return (
                <circle cx={cx} cy={cy} r={6} stroke="white" strokeWidth={1} fill="#2d9edf" />
            );
        }
        return null;
    }

    const CustomizedTooltip = props => {
        const { payload } = props;
        if (payload.length <= 0) {
            return null;
        }

        return (
            <Paper square={true}>
                <Box m={2}>
                    <Typography variant={'caption'}>DateTime</Typography>
                    <Typography variant={'body1'}>{ new Date(payload[0].payload.DateTime).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric', hour12: true }) }</Typography>
                    <Typography variant={'caption'}>Temperature</Typography>
                    <Typography variant={'body1'}>{ payload[0].payload.Temperature } &deg;C</Typography>
                    {/*<Typography variant={'caption'}>Event</Typography>*/}
                    {/*<Typography variant={'body1'}>{ payload[0].payload.Event }</Typography>*/}
                    {/*<Typography variant={'caption'}>Angle</Typography>*/}
                    {/*<Typography variant={'body1'}>{ payload[0].payload.Angle } &deg;</Typography>*/}
                </Box>

            </Paper>
        );
    }

    return (
        <Paper square={true} style={{padding: '10px', marginTop: '30px'}}>
            <Box m={4}></Box>
            { loading &&
                <CircularProgress />
            }
            { !loading && data.length > 0 &&
                <ResponsiveContainer width="100%" height={400}>

                        <LineChart
                            data={data}
                            margin={{
                                top: 0,
                                right: 30,
                                left: -18,
                                bottom: 5
                            }}

                        >
                            <CartesianGrid strokeDasharray="2 2" />
                            <XAxis dataKey="Date" type={'category'}
                                   tickFormatter={formatXAxis}
                                   allowDuplicatedCategory={false}

                                   // angle={-10}
                            />
                            <YAxis tickFormatter={formatYAxis} />
                            <Tooltip content={<CustomizedTooltip />}
                                // labelFormatter={(value) => new Date(value).toLocaleString(navigator.language, { hour12: true })}
                            />
                            {/*<Legend />*/}
                            <ReferenceLine y={0} stroke="#ccc" fill="#82ca9d"  />
                            <Line type="monotone" dataKey="Temperature"
                                  fill="#fff"
                                  stroke="#2d9edf"
                                  strokeWidth={4}
                                    // dot={<CustomizedDot />}
                            />
                        </LineChart>

                </ResponsiveContainer>
            }
            { !loading && !data &&
                <Box m={2}> No data for timeline. </Box>
            }

        </Paper>
    );
}
