import {useRefreshWhenVisible} from "ra-core";
import {useSelector} from "react-redux";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';

import KegstarLogo from "../components/logos/KegstarLogo";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import {useState} from "react";
import GetMarketColor from "./Client";

const useStyles = makeStyles(
    theme => ({
        navLink: {fontSize: '1.25rem', color: '#fff', textDecoration: 'none', marginLeft: '10px'},
        navSubLink: {color: '#bfbfbf', borderLeft: '1px solid #989ca5', paddingLeft: '10px'}
    })
);

const MystarNavigationBar = props => {
    const { classes: classesOverride, className, ...rest } = props;

    // const loading = useSelector(state => state.admin.loading > 0);
    const classes = useStyles(props);
    // const theme = useTheme();

    const [term, setTerm] = useState('');
    const [marketColor] = GetMarketColor();

    // const [marketColor, setMarketColor] = useState(getMarketColorCode());
    // const marketColor = getMarketColorCode();
    useRefreshWhenVisible();
    const handleSubmit = (event) => {
        event.preventDefault();
        window.location.href = `${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/search?term=${term}`;
        return false;
    };

    return (
        <React.Fragment>
            <Box display="flex" flexDirection="row" p={1} m={1} bgcolor="black" >
                <Box flexGrow={1} bgcolor="black" style={{marginLeft: '6%'}}>
                    <KegstarLogo />
                </Box>

                <Box p={1} bgcolor="black" >
                    <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/'} color={'inherit'} className={classes.navLink} >
                        <Button variant="contained" style={{height:'39px', border:'1px solid #666'}}>OPEN APP</Button>
                    </Link>
                </Box>
            </Box>

            <Box component="div" overflow="hidden" display="flex" flexDirection="row" p={1} m={1} bgcolor="black"
                 style={{margin: 0, marginTop:'-70px', marginLeft:'188px'}}>
                <Box p={1} bgcolor="black" alignSelf="flex-end">
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/'} color={'inherit'} className={classes.navLink} >
                            Home
                        </Link>
                    </Typography>
                </Box>
                <Box p={1} bgcolor="black" alignSelf="flex-end">
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/orders'} color={'inherit'} className={classes.navLink} >
                            Orders
                        </Link>
                    </Typography>
                </Box>
                <Box p={1} bgcolor="black" alignSelf="flex-end">
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/kegs/my-kegs'} color={'inherit'} className={classes.navLink} >
                            Kegs
                        </Link>
                    </Typography>
                </Box>
                { (localStorage.getItem('isMytarStarlightEnabled') === 'true') &&

                    <Box p={1} bgcolor="black" alignSelf="flex-end">
                        <Typography variant="h5">
                            <Link href={'/#/starlight/dashboard'} color={'inherit'} className={classes.navLink}>
                                Starlight
                            </Link>
                        </Typography>
                    </Box>

                }
                <Box p={1} bgcolor="black" alignSelf="flex-end">
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/reports/overview'} color={'inherit'} className={classes.navLink} >
                            Reports
                        </Link>
                    </Typography>
                </Box>
                <Box p={1} bgcolor="black" alignSelf="flex-end">
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/statements?filter=&limit=10&page=1&sort=-createdAt'} color={'inherit'} className={classes.navLink} >
                            Statements
                        </Link>
                    </Typography>
                </Box>
                <Box p={1} bgcolor="black" alignSelf="flex-end">
                    <Typography variant="h5">
                        <Link href={'/#/statements/invoices'} color={'inherit'} className={classes.navLink} >
                            Invoices
                        </Link>
                    </Typography>
                </Box>
                <Box p={1} bgcolor="black" alignSelf="flex-end">
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/account'} color={'inherit'} className={classes.navLink} >
                            Account
                        </Link>
                    </Typography>
                </Box>
                <Box p={1} bgcolor="black" alignSelf="flex-end" flexGrow={1}>
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/login'} color={'inherit'} className={classes.navLink} >
                            Logout
                        </Link>
                    </Typography>
                </Box>
                <Box p={1} bgcolor="black" alignSelf="flex-end" >
                    <Typography variant="h5">
                        <Link href={process.env.REACT_APP_TRACKS_MYSTAR_URL+'/notifications'} color={'inherit'} className={classes.navLink} >
                            Notifications
                        </Link>
                    </Typography>
                </Box>
            </Box>



        </React.Fragment>
    );
};

MystarNavigationBar.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.string,
};

const getMarketCode = () => {
    let colorCode = 'Global';
    switch (localStorage.getItem('cUuid')) {
        case process.env.REACT_APP_COUNTRY_UUID_AU:
            colorCode = 'AU';
            break;
        case process.env.REACT_APP_COUNTRY_UUID_NZ:
            colorCode = 'NZ';
            break;
        case process.env.REACT_APP_COUNTRY_UUID_UK:
            colorCode = 'UK';
            break;
        case process.env.REACT_APP_COUNTRY_UUID_US:
            colorCode = 'US';
            break;
        default:
            return colorCode;
    }
    return colorCode;
}
export default MystarNavigationBar;
