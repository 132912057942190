import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import EventBadge from "./EventBadge";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    eventNameContainer: {
       textAlign: 'center'
    },
    eventName: {
        fontWeight: 'bold',
        fontSize: 'large'
    }
}));


const EventSummary = props => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box m={5}></Box>
            <Box flexGrow={1}>
                <Typography variant={'h5'}>Average Number of Days</Typography>
            </Box>
            <Grid container spacing={3}
                  alignItems="center"
                  justify="center">
                <Grid item xs>
                    <EventBadge eventValue={15} /> <Box m={2}/>
                    <div className={classes.eventNameContainer}> <Typography className={classes.eventName}>{'Empty at Brewery'}</Typography> </div>
                </Grid>
                <Grid item xs>
                    <EventBadge eventValue={16} /> <Box m={2}/>
                    <div className={classes.eventNameContainer}><Typography className={classes.eventName}>{'Full at Brewery'}</Typography> </div>
                </Grid>
                <Grid item xs>
                    <EventBadge eventValue={5} /> <Box m={2}/>
                    <div className={classes.eventNameContainer}><Typography className={classes.eventName}>{'In Transit'}</Typography> </div>
                </Grid>
                <Grid item xs>
                    <EventBadge eventValue={20} /> <Box m={2}/>
                    <div className={classes.eventNameContainer}><Typography className={classes.eventName}>{'At Venue'}</Typography> </div>
                </Grid>
                <Grid item xs>
                    <EventBadge eventValue={27} /> <Box m={2}/>
                    <div className={classes.eventNameContainer}><Typography style={{fontWeight: 'bold', fontSize: '15.5px'}}>{'Distributor / Wholesaler'}</Typography></div>
                </Grid>
                <Grid item xs>
                    <EventBadge eventValue={15} /> <Box m={2}/>
                    <div className={classes.eventNameContainer}><Typography className={classes.eventName}>{'On Tap / Pouring'}</Typography> </div>
                </Grid>
                <Grid item xs>
                    <EventBadge eventValue={20} /> <Box m={2}/>
                    <div className={classes.eventNameContainer}><Typography className={classes.eventName}>{'Empty'}</Typography> </div>
                </Grid>
            </Grid>
            <Box m={5}></Box>
        </React.Fragment>
    )
}

export default EventSummary;

