// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const LocationDetailsExport = (records) => {
    const dataForExport = records.map(record => {
        const {
            id,	kegUuid, kegTurnUuid,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'organisationName', 'locationName', 'locationLatitude', 'locationLongitude', 'kegsAtLocation', 'likelyToBeEmpty'

        ], // order fields in the export
        rename:[
            'Organisation', 'Location', 'Latitude', 'Longitude', 'Kegs At Location', 'Ready For Collection'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'LocationDetails_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default LocationDetailsExport;
