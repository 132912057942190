import React, { Fragment } from 'react';
import {
    Datagrid, List, TextInput, DateField, NumberField, Filter, ShowButton,
    FunctionField, SelectInput, TextField, DateInput, ReferenceField
} from 'react-admin';
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

import IconButton from "@material-ui/core/IconButton";
import ButtonDownloadIcon from "@material-ui/icons/GetApp";
import {Close} from "@material-ui/icons";
import ListActions from "../../components/list/ListActions";
import CustomerInvoicesExport from "./CustomerInvoicesExport";
import ViewIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    organisationFilter: {
        width: '20em'
    },
    cardContent: {
        backgroundColor: theme.palette.secondary.light
    },
    gridListActionButton: {float: 'right', textAlign: 'right', borderLeft: '1px solid #E0E0E0'},
    closeFilterButton: {padding: '0px', marginTop: '15px'},
    closeFilterIcon: {fontSize: 'smaller'}
}));

const ListFilters = ({...props}) => {
    const classes = useStyles();
    return (
        <Filter {...props} >

            <DateInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"createdAt[from]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            <DateInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"createdAt[to]": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            {/*<KegnovaDatePickerInput source="createdAt[from]" label={'Invoice Date From'} alwaysOn/>*/}
            {/*<KegnovaDatePickerInput source="createdAt[to]" label={'Invoice Date To'} alwaysOn/>*/}

            <SelectInput source="invoiceType" label={'Invoice Type'} choices={[
                { id: 1, name: 'Order' },
                { id: 2, name: 'Monthly'}
            ]} options={{variant:'outlined'}} alwaysOn />

            {/*<DateInput source="invoiceDueDate" label={'Due Date From'} parse={v => { return dateInputTimezoneConverter(v, localStorage.getItem('timezone')) } } inputVariant={'standard'} alwaysOn/>*/}
            <DateInput source="invoiceDueDate" label={'Due Date'} inputVariant={'standard'} options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"invoiceDueDate": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />
            <DateInput source="actionedAt" label={'Actioned At'} inputVariant={'standard'} options={{InputProps: {
                    endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"actionedAt": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} /> </IconButton> </Fragment>)
                }}} />

            {/*<DateInput source="invoiceDueDate[to]" label={'Due Date To'} inputVariant={'standard'} />*/}

            <TextInput source={'orderId'} label={'Order No.'} resettable alwaysOn/>

        </Filter>
    )

}

const InvoiceList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    function showInvoiceField() {
        let result = false;

        if(process.env.REACT_APP_COUNTRY_UUID_US !== localStorage.getItem('cUuid')
            && process.env.REACT_APP_COUNTRY_UUID_UK !== localStorage.getItem('cUuid')) {
            result = true;
        }

        return result;
    }
    return (

        <Fragment>
            <List {...props}
                  actions={<ListActions />}
                  filters={<ListFilters classes={classes}/>}
                  bulkActionButtons={false}
                  exporter={CustomerInvoicesExport}
                  filter={ {organisationUuid: localStorage.getItem('oUuid')} }
            >
                <Datagrid className={'mystarInvoiceList'}>

                    <TextField emptyText source={'invoiceTypeText'} label={'Invoice Type'} />

                    <TextField emptyText source={'netsuiteInvDocNumber'} label={'Invoice No.'} />

                    <DateField source="actionedAt" label={'Order Actioned Date'} />

                    <TextField emptyText source={'orderId'} label={'Order No.'} />

                    <DateField source="dueDate" label={'Due Date'} />

                    { showInvoiceField() &&
                    <NumberField source={'subTotal'} label={'Total Before Discount'} sortable={false}
                                 options={{minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
                    }
                    { showInvoiceField() &&
                    <NumberField source={'discountAmount'} label={'Total Discount'} sortable={false}
                                 options={{minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
                    }
                    { showInvoiceField() &&
                    <NumberField source={'netTotal'} label={'Total (Excl. Tax)'} sortable={false}
                                 options={{minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
                    }

                    <FunctionField render={ record => <Link href={record.invoiceUrl} target="_blank" rel="noreferrer" style={{padding: 0, margin: 0, minWidth: '35px', minHeight: '35px'}} >
                        <ButtonDownloadIcon />
                    </Link>
                    }  link={false} sortable={false} label={''} source={'gridListAction'} />

                    {
                        showInvoiceField() &&  <FunctionField render={record => <Link href={`#/statements/invoices/${record.draftInvoiceUuid}`} > <ViewIcon /> </Link> } link={false} sortable={false} label={''} />
                    }

                </Datagrid>
            </List>
        </Fragment>
    );
};

export default InvoiceList;
