import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {CircularProgress, LinearProgress, Typography} from "@material-ui/core";
import SummaryBarChart from "./SummaryBarChart";
import Box from "@material-ui/core/Box";
import AverageTurns from "./AverageTurns";
import TemperatureSummary from "./TemperatureSummary";
import {useEffect, useState} from "react";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles((theme) => ({
    toggleButton: {backgroundColor: '#1f2124', color: '#fff', borderRadius: 0}
}));


const Averages = ({type, ...props}) => {

    const classes = useStyles();
    // Only show for ANZ
    const showTemperature = (parseInt(localStorage.getItem('cId')) === 1 || parseInt(localStorage.getItem('cId')) === 2);
    const [days, setDays] = useState(30);
    const [daysFilter, setDaysFilter] = useState(null);

    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/summary/avg-days?organisationUuid=${localStorage.getItem('oUuid')}`);
    const [avgDaysUrl, setAvgDaysUrl] = useState(baseUrl+'&days='+days);
    const [loading, setLoading] = useState(false);
    const [avgDaysData, setAvgDaysData] = useState(null);
    const [eventsData, setEventsData] = useState(null);

    const [baseTemperatureUrl, setBaseTemperatureUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/kegs/temperatureindicator?organisationUuid=${localStorage.getItem('oUuid')}`);
    const [temperatureData, setTemperatureData] = useState(null);

    // @todo change the endpoint here for events call
    const [baseEventUrl, setBaseEventUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/kegs/eventsavgdays?organisationUuid=${localStorage.getItem('oUuid')}`);
    // const [eventsUrl, setEventsUrl] = useState(baseEventUrl+'&days='+days); // @todo uncomment this once days is available
    const [eventsUrl, setEventsUrl] = useState(baseEventUrl);

    // Average Cycle Time + at Producer Call
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(avgDaysUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data;
                setAvgDaysData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [avgDaysUrl]);

    // Temperature
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(baseTemperatureUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data;
                setTemperatureData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [baseTemperatureUrl]);

    // EVENTS TIMELINE API CALL
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(eventsUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data;
                // console.log(data)
                setEventsData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [eventsUrl]);

    const handleChange = (event, value) => {
        setDaysFilter(value);
        setAvgDaysUrl(baseUrl+'&days='+value);
        setEventsUrl(baseEventUrl+'&days='+value);
        setDays(value);
    };

    return (
        <React.Fragment>
            <Box>

                <Grid container spacing={1}>
                    <Grid item xs={12} md={8}>
                        <Box m={3}></Box>
                        <Box display="flex" flexDirection="row">
                            <Box flexGrow={1}><Typography variant={'h5'}>Average Number of Days</Typography></Box>
                        </Box>
                        <Box m={1}></Box>
                        { loading && <Box m={5}><LinearProgress /></Box> }
                        { !loading && eventsData && <SummaryBarChart data={eventsData}/> }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {/*{ loading && <Box m={5}><CircularProgress /></Box> }*/}
                        {
                            showTemperature &&
                            <React.Fragment>
                                <Box m={3}></Box>
                                <Box><Typography variant={'h5'}>Temperature</Typography></Box>
                                <Box m={1}></Box>

                                <Box>
                                    { temperatureData && <TemperatureSummary temperatureData={temperatureData[0]} /> }
                                </Box>
                                <Box>
                                    { temperatureData && <TemperatureSummary temperatureData={temperatureData[1]} /> }
                                </Box>
                                <Box>
                                    { temperatureData && <TemperatureSummary temperatureData={temperatureData[2]} /> }
                                </Box>
                            </React.Fragment>
                        }

                        <Box m={1}></Box>

                        <Box display={'flex'}>
                            <Box m={1}><Typography variant={'h5'}>{days} Days</Typography></Box>
                            <Box>
                                <ToggleButtonGroup value={days} aria-label="average-turns-label">
                                    <ToggleButton className={classes.toggleButton} onClick={handleChange} value={30}>30</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} onClick={handleChange} value={60}>60</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} onClick={handleChange} value={90}>90</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} onClick={handleChange} value={120}>120</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} onClick={handleChange} value={150}>150</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Box>
                        <Box>
                            { loading && <Box m={5}><CircularProgress /></Box> }
                            { !loading && avgDaysData && <AverageTurns avgDaysData={avgDaysData}/>}
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default Averages;

