import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis, CartesianGrid,
    Tooltip, LabelList,
    Legend, Rectangle, ResponsiveContainer
} from "recharts";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import LegendIcon from '@material-ui/icons/Stop';
import ReadyToFillIcon from "../../assets/images/kegs-empty.png";
import DistributorIcon from "../../assets/tracks/images/custom-icons/kegs.svg";
import {CircularProgress} from "@material-ui/core";



export default function SummaryBarChart({data}) {

    function getColorCode (id) {
        let fill = '#000';
        switch (id) {
            case 1:
                fill = '#f6664b';
                break;
            case 2:
                fill = '#689F38';
                break;
            case 3:
                fill = '#7B7B7B';
                break;
            case 4:
                fill = '#F57C00';
                break;
            case 5:
                fill = '#0288D1';
                break;
            case 6:
                fill = '#FEDB00';
                break;
            case 7:
                fill = '#D32F2F';
                break;
        }
        return fill;
    }

    const eventClick = (EventType) => {
        console.log(EventType);
        // Redirect
    }

    const CustomBar = (props) => {

        const {id, Label, Name, Days, EventType} = props;

        let fill = getColorCode(id);
        //business logic here to update fill color explicitly

        //use explicit fill here, or use the additional css class and make a css selector to update fill there
        return <Rectangle {...props} fill={fill}
                          className={`summaryBarChartBar-${id}`} name={`# ${Name}`}
                          // stroke="white"
                          onClick={()=>{eventClick(EventType)}}
        />
    };

    const renderLegend = (props) => {

        return (
            <Box m={3}>
                <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '1em', justifyContent: 'center', alignItems: 'center'}}>
                    {
                        data.map((entry, index) => (
                            <div>
                                <LegendIcon style={ {color: getColorCode(entry.id), verticalAlign: 'middle' }} fontSize={'large'}
                                            // stroke={'#fff'}
                                />
                                <span style={{color: '#fff'}}>{entry.Name}</span>
                            </div>
                        ))
                    }
                </div>
            </Box>
        );
    }
    const formatXAxis = (tickItem) => {
        // console.log(window.navigator.userLanguage)

       return '';
    }
    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
        const radius = 14;
        let positionCircle = (value < 5) ? 0 : 55;
        let positionText = (value < 5) ? 0 : 56;
        return (
            <React.Fragment>
                <g>
                    {/*<circle cx={x + width / 2} cy={y - radius + positionCircle} r={radius} fill={'none'} stroke="white"/>*/}
                    <text x={x + width / 2} y={y - radius + positionText } fill="#fff" textAnchor="middle" dominantBaseline="middle" fontSize={18}>
                        {value}
                    </text>
                </g>
            </React.Fragment>
        );
    };
    const CustomizedTooltip = props => {
        const { payload } = props;
        if (payload.length <= 0) {
            return null;
        }

        return (
            <Paper square={true}>
                <Box m={2}>
                    {/*<Typography variant={'caption'}>Event </Typography>*/}
                    <Typography variant={'body1'}>{ payload[0].payload.Name }</Typography>
                    <Typography variant={'caption'}>Average Days</Typography>
                    <Typography variant={'body1'}>{ payload[0].payload.Days }</Typography>
                </Box>

            </Paper>
        );
    }

    const responsiveContainerHeight = (parseInt(localStorage.getItem('cId')) === 1 || parseInt(localStorage.getItem('cId')) === 2) ? 625 : 475;

    return (
        <React.Fragment>
            { !data && <Box m={5}><CircularProgress /></Box> }
            {
                data &&
                <Box>
                    <Paper square={true} style={{backgroundColor: '#1f2124'}}>
                        <Box m={5}></Box>
                        <ResponsiveContainer width="100%" height={responsiveContainerHeight}>
                            <BarChart
                                data={data}
                                margin={{
                                    top: 0,
                                    right: 10,
                                    left: 0,
                                    bottom: 0
                                }}
                            >
                                {/*<CartesianGrid strokeDasharray="1 1" />*/}
                                <XAxis stroke="white" dataKey="Label"  />
                                <YAxis stroke="white" />
                                <Tooltip content={<CustomizedTooltip />} />
                                <Legend content={renderLegend}/>

                                <Bar shape={CustomBar} dataKey="Days" barSize={55}>
                                    <LabelList dataKey="Days" content={renderCustomizedLabel} />
                                </Bar>
                                {/*<Bar dataKey="days" fill="#8884d8" />*/}

                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Box>
            }
        </React.Fragment>
    );
}
