// @ts-nocheck
import * as React from 'react';
import { useState, FC, useRef } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    FunctionField,
    DateField,
    NumberField,
    Filter, useUnselectAll, DateTimeInput,
    FilterProps, TextInput, SelectInput, DateInput, useAuthenticated,
    ReferenceInput, AutocompleteInput, SimpleForm, Toolbar, useRefresh, useLoading, Pagination
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Link,
    Box,
    Button,
    Paper,
    Typography,
    Divider,
    LinearProgress,
    Drawer,
    CircularProgress
} from "@material-ui/core";
import CyclesExport from "./CyclesExport";
import { useFormState } from 'react-final-form';
import axios from "axios";
import CloseIcon from '@material-ui/icons/Close';
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import CycleListActions from "./CycleListActions";
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import {setCurrentFilters, applyCurrentFilter} from "../../utilities/Common";

const useStyles = makeStyles(theme => ({
    links: {
        color: '#2d9edf'
    },
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    orderFilter: {
        width: '145px'
    },
    kegFilter: {
        width: '180px'
    },
    skuFilter: {
        width: '110px'
    },
    productFilter: {
        width: '200px'
    },
    batchNumberFilter: {
        width: '160px'
    },
    lastDispatchId: {
        width: '140px'
    }
}));

const getStartDateFrom = () => {
    let days = 90;
    let result = new Date( new Date() - days * 24 * 60 * 60 * 1000);
    return result.toISOString().slice(0, 10);
}
const getStartDateTo = () => {
    return new Date().toISOString().slice(0, 10);
}
const ListFilters = (props: Omit<FilterProps, 'children'>) => {
    const classes = useStyles();

    setCurrentFilters(props,
        ['cycleKegSerialNumber', 'order', 'skuUuid', 'billingStartAtFrom', 'billingStartAtTo', 'productUuid', 'batchNumber', 'cycleStatus', 'filledAt_from', 'filledAt_to',
        'kegDispatchId']);

    return (
        <React.Fragment>
            <Filter {...props} style={{paddingBottom: '25px'}}>
                <TextInput label={'Keg'} source={'cycleKegSerialNumber'} alwaysOn resettable={true} className={classes.kegFilter} />
                <TextInput source={'order'} alwaysOn resettable={true} className={classes.orderFilter} />
                <DateInput label={'Start Date From'} source={'billingStartAtFrom'} resettable={true} alwaysOn />
                <DateInput label={'Start Date To'} source={'billingStartAtTo'}  resettable={true} alwaysOn />
                <ReferenceInput source="productUuid" reference="products" className={classes.productFilter}
                                filter={{organisationUuid: localStorage.getItem('oUuid')}}
                                resource="name" label={'Product'}
                                alwaysOn allowEmpty={false} resettable>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <TextInput source={'batchNumber'} label={'Batch Number'} resettable={true} className={classes.batchNumberFilter} />

                <ReferenceInput source="skuUuid" reference="skus" label={'SKU'} className={classes.skuFilter} resettable >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>

                <SelectInput source="cycleStatus" label={'Status'} choices={[
                    { id: null, name: 'All' },
                    { id: 1, name: 'Open' },
                    { id: 0, name: 'Closed' }
                ]} options={{variant:'outlined'}} allowEmpty={false} />

                <DateTimeInput label={'Filled At From'} source={'filledAt_from'} resettable={true}
                               format={v => {return v} } parse={v => {return v} } />
                <DateTimeInput label={'Filled At To'} source={'filledAt_to'} resettable={true}
                               format={v => {return v} } parse={v => {return v} } />

                {/*<DateInput label={'Location Start At  From'} source={'currentLocationStartAtTo'} resettable={true} />*/}
                {/*<DateInput label={'Location Start At To'} source={'currentLocationStartAtFrom'}  resettable={true} />*/}

                <TextInput source={'kegDispatchId'} label={'Last Dispatch Id'} resettable={true} className={classes.lastDispatchId} />

            </Filter>
        </React.Fragment>
    )
}

const StopLocation = ({record}) => {

    return(
        <React.Fragment>
            { record.startOrganisationName } <br/>
            { record.stopLocationName ? <span style={{color: '#999'}}>{record.stopLocationName}, {record.stopLocationPostcode}</span> : null }
            {
                record.stopReason &&
                <span style={{color: '#90be51'}}> ({record.stopReason})</span>
            }
        </React.Fragment>
    )
}
const LastKnownLocation = ({record}) => {

    return(
        <React.Fragment>
            { record.lastKnownLocationName }
        </React.Fragment>
    )
}
const CycleStatus = ({record}) => {

    return(
        <React.Fragment>
            { record.cycleStatus } <br/>
            <span style={{color: '#999'}}>{ record.stopReason }</span>
        </React.Fragment>
    )
}

const getFormattedDate = (date) => {
    return new Date(date).toISOString().slice(0, 10) + ' ' + new Date(date).toISOString().slice(11, 19);
}

const UpdateProductToolbar = ({selectedUuids, state, ...props}) => {
    const refresh = useRefresh();
    const formState = useFormState();
    let productUuid = formState.values['productUuid'];
    let batchNumber = formState.values['batchNumber'];
    let filledAt = formState.values['filledAt'];

    let orderId = localStorage.getItem('order_Filter');
    let cycleKegSerialNumber = localStorage.getItem('cycleKegSerialNumber_Filter');
    let skuUuid = localStorage.getItem('skuUuid_Filter');
    let cycleStatus = localStorage.getItem('cycleStatus_Filter');
    let billingStartAtFrom = localStorage.getItem('billingStartAtFrom_Filter');
    let billingStartAtTo = localStorage.getItem('billingStartAtTo_Filter');
    let kegDispatchId = localStorage.getItem('kegDispatchId_Filter');
    let filledAtFrom = localStorage.getItem('filledAt_from_Filter') ? getFormattedDate(localStorage.getItem('filledAt_from_Filter')) : null;
    let filledAtTo = localStorage.getItem('filledAt_to_Filter') ? getFormattedDate(localStorage.getItem('filledAt_to_Filter')) : null;
    // let currentLocationStartAtFrom = localStorage.getItem('currentLocationStartAtFrom_Filter');
    // let currentLocationStartAtTo = localStorage.getItem('currentLocationStartAtTo_Filter');

    let payload = {
        organisationUuid: localStorage.getItem('oUuid'),
        cycleUuids: selectedUuids,
        productUuid: productUuid,
        batchNumber: batchNumber,
        orderId: orderId,
        cycleKegSerialNumber: cycleKegSerialNumber,
        skuUuid: skuUuid,
        billingStartAtFrom: billingStartAtFrom,
        billingStartAtTo: billingStartAtTo,
        cycleStatus: cycleStatus,
        filledAt: {
            datetime: filledAt,
            timezone: (Intl && Intl.DateTimeFormat().resolvedOptions().timeZone) ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC'
        },
        kegDispatchId: kegDispatchId,
        filledAtFrom: filledAtFrom,
        filledAtTo: filledAtTo
    }

    // currentLocationStartAtFrom: currentLocationStartAtFrom,
    // currentLocationStartAtTo: currentLocationStartAtTo,

    const handleUpdateProduct = async (payload) => {
        state.setSelection(null);
        await saveProduct(payload)
    };

    const saveProduct = async (payload) => {

        state.setUpdateLoading(true);
        state.setSuccessMessage(null);
        state.setErrorMessage(null);
        state.setRefreshing(true);
        state.setCheckedBox(false);

        try {
            let url = `${process.env.REACT_APP_TRACKS_API_URL}/cycles/product-and-batch-assign`;
            const options = {
                headers: {"Access-Control-Allow-Origin": "*", 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey') }
            };
            const result = await axios.post(url, payload, options);
            state.setUpdateLoading(false);
            state.setSuccessMessage(`Updated product(s) successfully!`);
            state.setIsDrawerOpen(false);
            state.unselectAll('cycles');
            setTimeout(function(){
                refresh();
                state.setRefreshing(false);
                state.setSuccessMessage(null);
                state.setCheckedBox(true);
            }, 7000);
        } catch (error) {
            state.setSuccessMessage(null);
            state.setUpdateLoading(false);
            state.setRefreshing(false);
            state.setErrorMessage(`Error in updating product(s)! ${error}`);
            state.setIsDrawerOpen(false);
            state.setCheckedBox(true);
            state.unselectAll('cycles');
        }
    }

    return(
        <Toolbar {...props}>
            <Box m={1} display="flex" justifyContent="center">
                <Button variant={'contained'} onClick={()=> handleUpdateProduct(payload) }> Update </Button>
            </Box>
        </Toolbar>
    )
};

const CycleList: FC<ListProps> = props => {
    useAuthenticated();
    const loading = useLoading();
    const scrollable = useRef(null);
    let defaultFilterValues = { };
    defaultFilterValues = applyCurrentFilter(defaultFilterValues,
        ['cycleKegSerialNumber', 'order', 'skuUuid', 'billingStartAtFrom', 'billingStartAtTo', 'productUuid', 'batchNumber', 'cycleStatus', 'filledAt_from', 'filledAt_to',
        'kegDispatchId', 'currentLocationStartAtFrom', 'currentLocationStartAtTo']);

    let defaultStartDateFrom = getStartDateFrom();
    let defaultStartDateTo = getStartDateTo();
    defaultFilterValues['billingStartAtFrom'] = defaultStartDateFrom;
    defaultFilterValues['billingStartAtTo'] = defaultStartDateTo;

    const unselectAll = useUnselectAll();
    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [checkedBox, setCheckedBox] = useState(false);
    const [hideBulk, setHideBulk] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selection, setSelection] = useState(null);
    const state = { setErrorMessage: setErrorMessage,
                    setSuccessMessage: setSuccessMessage, setIsDrawerOpen: setIsDrawerOpen,
                    setUpdateLoading: setUpdateLoading, unselectAll: unselectAll,
                    setSelection: setSelection, checkedBox: checkedBox, setHideBulk: setHideBulk,
                    setRefreshing: setRefreshing,
                    setCheckedBox: setCheckedBox }
    let selectedUuids = [];

    const handleClose = props => {
        setIsDrawerOpen(false);
        setHideBulk(true);
    }

    const handleSelect = (state, selectedUuids, props) => {

        state.setSelection(selectedUuids);
        state.setIsDrawerOpen(true);
        // return null;
    }
    const UpdateProductActionButtons = ({state , ...props}) => {

        let selectedIds = props.selectedIds;

        if (selectedIds.length > 0){
            selectedUuids = props.selectedIds;
            setCheckedBox(true);
        } else {
            selectedUuids = [];
            state.setSelection(null);
            setCheckedBox(false);
            setHideBulk(true);
        }

        return (
            // <React.Fragment></React.Fragment>
            <div style={{marginRight: '660px'}}>
                { !isDrawerOpen &&
                <Alert severity="info" style={{padding: 0, marginTop: '-10px', paddingLeft: '10px', paddingRight: '10px'}}>
                    Apply Product & Batch Number on selected items? <Button variant={'contained'} onClick={()=> handleSelect(state, selectedUuids, props) } > Yes </Button>
                </Alert>
                }
            </div>
        );
    };

    const AppliedFor = ({selection}) => {
        if(!selection) return (
            <Alert severity="info">
                Apply Product & Batch for all items.
            </Alert>
        );

        if(selection.length > 0 && checkedBox) {
            return (
                <Alert severity="info">
                    Apply Product and Batch for { selection.length } items.
                </Alert>
            )
        } else {
            return (
                <Alert severity="info">
                    Apply Product & Batch for all filtered items.
                </Alert>
            )
        }

    }
    const scroll = (scrollOffset) => {
        scrollable.current.scrollLeft += scrollOffset;
    };

    const CycleListPagination = props => <Pagination style={{float: 'left'}} rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

    return (
        <React.Fragment>
            {/*<Box display="flex" flexDirection="row-reverse" >*/}
            {/*    <Box>*/}
            {/*        <Button onClick={() => scroll(350)}> <KeyboardArrowRight /> </Button>*/}
            {/*    </Box>*/}
            {/*    <Box>*/}
            {/*        <Button onClick={() => scroll(-350)}> <KeyboardArrowLeft /> </Button>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <Container style={{width: 'auto', maxWidth: '97vw', overflowX: 'scroll', padding: '8px'}} ref={scrollable}>
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', margin: '2px', marginBottom: '20px', padding:'5px'}}>
                    <Box p={1}><CircularProgress size={20} color={'#fff'} /> Please wait...</Box>
                </Box>
                }
                <Box>
                    { updateLoading &&
                    <Box>
                        <LinearProgress />
                    </Box>
                    }
                    { refreshing &&
                    <Box>
                        <LinearProgress />
                    </Box>
                    }
                    { errorMessage &&
                    <Box>
                        <Alert severity="error" style={{display: 'flex'}}>
                            <AlertTitle variant={'h6'}> Error </AlertTitle>
                            { errorMessage }
                        </Alert>
                        <Box m={2} />
                    </Box>
                    }
                    { successMessage &&
                    <Box>
                        <Alert severity="success" style={{display: 'flex'}}>
                            <AlertTitle variant={'h6'}> Success </AlertTitle>
                            { successMessage }
                        </Alert>
                        <Box m={2} />
                    </Box>
                    }
                </Box>

                { !state.checkedBox &&
                    <Box>
                        <Alert severity="info">
                            Select kegs by ticking checkboxes or click to
                            <Link onClick={() => { state.setIsDrawerOpen(true); state.setHideBulk(false);  }} disabled={state.checkedBox}
                            style={{fontWeight: 'bold', cursor: 'pointer'}}> Select all </Link>
                            and apply Product and Batch.
                            {/*Want to assign Product and Batch? Select kegs by ticking checkboxes or click*/}
                            {/*<Button variant={'contained'} onClick={() => { state.setIsDrawerOpen(true);  state.setHideBulk(false);  }} disabled={state.checkedBox}*/}
                            {/*        style={{marginLeft: '7px', marginRight: '5px'}}*/}
                            {/*> Apply Product & Batch </Button> and apply Product and Batch.*/}
                        </Alert>
                        <Box m={2}></Box>
                    </Box>
                }

                {
                    !updateLoading && !refreshing &&
                    <List
                    {...props}
                        bulkActionButtons={ !hideBulk ? false : <UpdateProductActionButtons state={state} /> }
                        // bulkActionButtons={false}
                        // bulkActionButtons={<Aside />}
                        // aside={<Aside />}
                        actions={<CycleListActions state={state} />}
                        filters={<ListFilters />}
                        filter={{organisationUuid: localStorage.getItem('oUuid')}}
                        filterDefaultValues={defaultFilterValues}
                        sort={{ field: 'billingStartAt', order: 'desc' }}
                        exporter={CyclesExport}
                        pagination={<CycleListPagination />}
                        >
                        <Datagrid
                        // rowClick={handleClick}
                        >
                        <FunctionField label="Keg" render={record =>
                        <Link className={classes.links}
                        href={ `${process.env.REACT_APP_TRACKS_MYSTAR_URL}/kegs/${record.kegId}/cycle/${record.cycleId}` }>
                    {record.serialNumber}</Link> } />
                        <FunctionField label="Order" render={record =>
                        <Link className={classes.links}
                        href={ `${process.env.REACT_APP_TRACKS_MYSTAR_URL}/orders/${record.order}` }>{record.order}</Link> } />
                        <TextField label={'SKU'} source={'skuName'} sortable={false} />
                    {/*<TextField label={'Owner Organisation'} source={'organisationName'} sortable={false} />*/}
                    {/*<TextField label={'Status'} source={'cycleStatus'} sortable={false} />*/}
                        <DateField label={'Start Date'} source={'billingStartAt'} locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'} />
                        <DateField label={'Stop Date'} source={'billingStopAt'} locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'}/>
                        <FunctionField label="Status" render={record =>
                        <CycleStatus record={record}  /> } sortBy={'cycleStatus'} />
                        <NumberField label={'Age'} source={'age'} sortable={false} />
                    { parseInt(localStorage.getItem('cId')) !== 3 && parseInt(localStorage.getItem('cId')) !== 4 ? <NumberField label={'Billable Days'} source={'billableDays'} sortable={false} /> : null }
                        <TextField label={'Start Location'} source={'startLocationName'} sortable={false} />
                        <FunctionField label="Stop Location" render={record =>
                        <StopLocation record={record}  /> } sortable={false} />

                    { parseInt(localStorage.getItem('cId')) !== 3 && parseInt(localStorage.getItem('cId')) !== 4 ?
                        <FunctionField label="Last Known Location" render={record =>
                        <LastKnownLocation record={record}  /> } sortable={false} />
                        : null }

                        <DateField label={'Filled At'} source={'filledAt'} locales={(localStorage.getItem('cId') === 4) ? 'en-US' : undefined}
                        options={{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }} />

                    {/*<FunctionField label="Filled At" render={record =>*/}
                    {/*    new Date(record.filledAt).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB',*/}
                    {/*        { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true })*/}
                    {/*} sortBy={'cycleStatus'} />*/}

                        <TextField label={'Product'} source={'productName'} sortable={false} />
                        <TextField label={'Batch Number'} source={'batchNumber'} sortable={false} />
                        <TextField label={'Last Dispatch Id'} source={'billingLastDispatchId'} sortable={false} />
                        <DateField label={'Last Dispatch Created At'} source={'billingLastDispatchCreatedAt'} locales={(localStorage.getItem('cId') === 4) ? 'en-US' : undefined}
                        options={{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }} />
                    {/*<FunctionField label="Last Dispatch Created At" render={record =>*/}
                    {/*    new Date(record.billingLastDispatchCreatedAt).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB',*/}
                    {/*        { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true })*/}
                    {/*} sortBy={'cycleStatus'} />*/}
                        </Datagrid>
                        </List>

                }
                <Drawer
                    variant="persistent"
                    open={isDrawerOpen}
                    anchor="right"
                    onClose={handleClose}
                >
                    {/*<UpdateProductActionButtons state={state} {...props}/>*/}
                    <Paper square={true} >
                        {/*<Button >Close</Button>*/}
                        <CloseIcon onClick={ handleClose } style={{float: 'right', padding: '10px'}}  />
                        <Box m={2}>
                            <Typography variant={'h5'} style={{minWidth: '290px'}}>Update Product and Batch</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <AppliedFor selection={selection} />
                        </Box>
                        <SimpleForm toolbar={<UpdateProductToolbar selectedUuids={selection} state={state} />} >
                            <ReferenceInput source="productUuid" reference="products"
                                            filter={{organisationUuid: localStorage.getItem('oUuid')}}
                                            defaultValue={localStorage.getItem('productUuid_Filter')}
                                            resource="name" label={'Product'}
                                            alwaysOn allowEmpty={false} resettable>
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <TextInput label={'Batch Number'} source={'batchNumber'} defaultValue={localStorage.getItem('batchNumber_Filter')} alwaysOn resettable={true}  />
                            <DateTimeInput label={'Filled At'} source={'filledAt'} alwaysOn resettable={true}
                                           defaultValue={new Date().toLocaleString()}  parse={v => {return v} } />
                        </SimpleForm>
                    </Paper>
                </Drawer>
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', margin: '2px', marginBottom: '20px', padding:'5px'}}>
                    <Box p={1}><CircularProgress size={20} color={'#fff'} /> Please wait...</Box>
                </Box>
                }

            </Container>


        </React.Fragment>
    );
};

export default CycleList;
