// @ts-nocheck
import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    Filter,
    FilterProps, ReferenceInput, AutocompleteInput, useAuthenticated, useLoading,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {Box, CircularProgress, Container} from "@material-ui/core";
import LocationDetailsExport from "./LocationDetailsExport";
import LocationDetailsActions from "./LocationDetailsActions";

const useStyles = makeStyles(theme => ({
    links: {
        color: '#2d9edf'
    },
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    cycleStatusFilter: {
        width: '50px'
    },
    locationDetailsFilter: {
        width: '350px',
        // backgroundColor: '#1f2124',
        // color: '#fff'
    }
}));

const ListFilters = (props: Omit<FilterProps, 'children'>) => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <ReferenceInput source="locationUuid" reference="starlight/dashboard/locations/details" label={'Location'}
                            filter={{organisationUuid: localStorage.getItem('oUuid')}}
                            sort={{name: 'asc'}}
                            alwaysOn resettable
                            className={classes.locationDetailsFilter}>
                <AutocompleteInput optionText="locationName" />
            </ReferenceInput>
        </Filter>
    )
}

const RecordRowClick = (id, basePath, record) => {
    // console.log(id, basePath, record);
    let url = `/starlight/kegs/list`;
    if(record.locationUuid) {
        url = `/starlight/kegs/list/${record.locationUuid}`;
    }
    return url;
};

const LocationDetailsList: FC<ListProps> = props => {
    useAuthenticated();
    const loading = useLoading();
    // const classes = useStyles();
    return (
        <React.Fragment>
            <Container style={{ maxWidth: '1230px', padding: '6px'}} >
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', margin: '2px', marginBottom: '20px', padding:'5px'}}>
                    <Box p={1}><CircularProgress size={20} color={'#fff'} /> Please wait...</Box>
                </Box>
                }
                <List
                    {...props}
                    actions={<LocationDetailsActions />}
                    bulkActionButtons={false}
                    filters={<ListFilters />}
                    filter={{organisationUuid: localStorage.getItem('oUuid')}}
                    perPage={10}
                    sort={{ field: 'id', order: 'desc' }}
                    exporter={LocationDetailsExport}
                >
                    <Datagrid style={{ maxWidth: '1230px'}} id={'starlight-location-details-table'} rowClick={RecordRowClick} >

                        <TextField label={'Organisation'} source={'organisationName'} style={{paddingRight: '65px'}} />
                        <TextField label={'Location'} source={'locationName'}  />
                        <TextField label={'Kegs at Location'} source={'kegsAtLocation'}  />
                        <TextField label={'State'} source={'locationState'}  />
                        <TextField label={'Average Days'} source={'kegAvgDays'}  />
                        <TextField label={'Location Role'} source={'locationTypeDisplay'}  />
                        <TextField label={'Ready for Collection'} source={'likelyToBeEmpty'} emptyText={'-'} />

                    </Datagrid>
                </List>
            </Container>
        </React.Fragment>
    );
};

export default LocationDetailsList;
