// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const KegsListExport = (records) => {
    //
    const dataForExport = records.map(record => {
        const {
            id,	turnEndOrganisationUuid, locationUuid, turnStartAt, turnEndAt, kegLastPing, leftAtProducer, kegStatus,
            kegsReadyForCollection, kegsReadyForCollectionEvent, kegsReadyForCollectionAt, kegUuid, turnEndOrganisationName,
            cyclesUuid, lastKnownLocationsUuid,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'kegSerialNumber', 'lastKnownLocation', 'organisationName',	'kegLastPingDisplay',	'deviceId' , 'leftAtProducerDisplay','turnStartAtDisplay', 'turnEndAtDisplay', 'turnNoOfDays', 'kegStatusDisplay', 'kegsReadyForCollectionDisplay',	'kegsReadyForCollectionAtDisplay'
        ], // order fields in the export
        rename:[
            'Keg Serial Number', 'Last Known Location', 'Organisation',	'Last Ping',	'Device Id' , 'Left At Producer','Cycle Start At', 'Cycle End At', 'Turn No of Days', 'Keg Status', 'Ready For Collection',	'Ready For Collection At'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'KegsList_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default KegsListExport;
